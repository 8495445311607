<template>
  <div>
    <CSpinner v-if="$apollo.queries.client.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="client"
        :name="itemName"
        :nid="client.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/contracts/clients'})"
            />
          </CCol>
          <CCol sm="8">
            <h2>{{itemName}}</h2>
          </CCol>
          <CCol sm="2">
            <CButton
                class="btn-validation"
                :color="clientSave.isValid ? 'success': 'info'"
                :variant="clientSave.isValid ? null: 'outline'"
                @click="toggleIsValid"
              >
              {{this.clientSave.isValid ? 'Valid': 'Validate'}}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <ClientForm
            v-bind:clientIn="clientEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <CRow>
          <CCol sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({ path: '/contracts/clients'})"
            />
          </CCol>
          <CCol sm="8" />
          <CCol sm="2">
            <CButton
                class="btn-validation"
                :color="clientSave.isValid ? 'success': 'info'"
                :variant="clientSave.isValid ? null: 'outline'"
                @click="toggleIsValid"
            >
              {{this.clientSave.isValid ? 'Valid': 'Validate'}}
            </CButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import ClientForm from "@/apps/contracts/components/ClientForm";

const queryClient = gql`
query client($nid: ID!) {
  client(nid: $nid) {
    nid
    name
    isValid
    salesSegment
    accountOwner {
      nid
      firstName
      lastName
    }
    msAccountOwner {
      nid
      firstName
      lastName
    }
    vendor{
      nid
      name
    }
    salesRegion{
      nid
      name
    }
    salesOffice{
      nid
      name
    }
    debitorName
    debitorNumber
    msDebitorNumber
    debitorCountry {
      nid
      name
    }
    debitorStreet
    debitorPostalCode{
      nid
      postalCode
    }
    debitorCity
    debitorContactSalutation
    debitorContactFirstName
    debitorContactLastName
    debitorEmail
    billomatId
    msBillomatId
    country {
      nid
      name
    }
    location
    street
    postalCode{
      nid
      postalCode
    }
    city
    email
    homepage
    bankAccountOwner
    bankAccountNumber
    bankNumber
    bankName
    swift
    iban
    sepaDate
    sepaMandate
    msSepaDate
    msSepaMandate
    taxNumber
    vatNumber
    currency
    vat
    notes
    msDescription
    msDeliveryRegions
    msEmail
    msDebitorContactSalutation
    msDebitorContactFirstName
    msDebitorContactLastName
    msDebitorEmail
    portfolioSettings {
      nid
      entity
      filterPrice0To100k
      scorePrice0To100k
      filterPrice100kTo150k
      scorePrice100kTo150k
      filterPrice150kTo200k
      scorePrice150kTo200k
      filterPrice200kTo250k
      scorePrice200kTo250k
      filterPrice250kTo300k
      scorePrice250kTo300k
      filterPrice300kTo350k
      scorePrice300kTo350k
      filterPrice350kTo400k
      scorePrice350kTo400k
      filterPrice400kTo450k
      scorePrice400kTo450k
      filterPrice450kTo500k
      scorePrice450kTo500k
      filterPrice500kTo550k
      scorePrice500kTo550k
      filterPrice550kTo600k
      scorePrice550kTo600k
      filterPrice600kToInf
      scorePrice600kToInf
      filterSize0To75
      scoreSize0To75
      filterSize75To100
      scoreSize75To100
      filterSize100To120
      scoreSize100To120
      filterSize120To140
      scoreSize120To140
      filterSize140To160
      scoreSize140To160
      filterSize160To180
      scoreSize160To180
      filterSize180To200
      scoreSize180To200
      filterSize200To225
      scoreSize200To225
      filterSize225To250
      scoreSize225To250
      filterSize250To275
      scoreSize250To275
      filterSize275To300
      scoreSize275To300
      filterSize300ToInf
      scoreSize300ToInf
      scorePricePerSqm0To1000
      scorePricePerSqm1000To1200
      scorePricePerSqm1200To1400
      scorePricePerSqm1400To1600
      scorePricePerSqm1600To1800
      scorePricePerSqm1800To2000
      scorePricePerSqm2000To2200
      scorePricePerSqm2200To2400
      scorePricePerSqm2400To2600
      scorePricePerSqm2600To2800
      scorePricePerSqm2800To3000
      scorePricePerSqm3000ToInf
      filterUtilizationApartmentHouse
      filterUtilizationDetachedHouse
      filterUtilizationDuplex
      filterUtilizationTwoFamiliesHouse
      filterConstructionMethodBlockhouse
      filterConstructionMethodPrefab
      filterConstructionMethodSolid
      filterPropertyYes
      filterPropertyReserved
      filterPropertyExpected
      filterPropertyNo
      filterExpansionStagePriceFitting
      filterExpansionStagePriceLego
      filterExpansionStagePriceReady
    }
  }
}
`
const updateClient = gql`
  mutation updateClient($nid: ID!, $client: ClientInput!){
    updateClient(nid: $nid, client: $client){
        nid
    }
  }
`
const deleteClient = gql`
  mutation deleteClient($nid: ID!){
    deleteClient(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "Client",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    ClientForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      client: {},
      clientEdited: {},
      clientSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      //multiselect object
      data.accountOwner = data.accountOwner ? {nid: data.accountOwner.nid} : null;
      data.msAccountOwner = data.msAccountOwner ? {nid: data.msAccountOwner.nid} : null;
      data.vendor = data.vendor ? {nid: data.vendor.nid} : null;
      data.salesRegion = data.salesRegion ? {nid: data.salesRegion.nid} : null;
      data.salesOffice = data.salesOffice ? {nid: data.salesOffice.nid} : null;
      data.country = data.country ? data.country.name : null;
      data.debitorCountry = data.debitorCountry ? data.debitorCountry.name : null;
      data.debitorPostalCode = data.debitorPostalCode ? data.debitorPostalCode.postalCode : null;
      data.postalCode = data.postalCode ? data.postalCode.postalCode : null;
      data.portfolioSettings = data.portfolioSettings ? data.portfolioSettings.map(item => {
        delete item.idx
        return item
      }) : null;

      this.clientSave = data;
      // Changed clients are set back to not-validated
      this.clientSave.isValid = false;
      this.clientEdited.isValid = false;
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.clientSave;
      if(updatedObj.portfolioSettings){
        updatedObj.portfolioSettings = updatedObj.portfolioSettings.map(({__typename, ...keepAttrs}) => keepAttrs)
      }
      this.$apollo.mutate({
        mutation: updateClient,
        variables: {
          nid: nid,
          client: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.clientEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/clients'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.clientEdited = clonedeep(this.$apolloData.data.client);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteClient,
        variables: {
          nid: this.client.nid
        }
      })
      .then(res => {
        this.$emit('delete-client', this.client.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/contracts/clients'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleIsValid(){
      if(this.allowEdit){
        this.clientEdited.isValid = ! this.clientEdited.isValid;
        this.clientSave.isValid = ! this.clientSave.isValid;
      }
    },
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.clientEdited.name
    }
  },
  apollo: {
    client: {
      query: queryClient,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
       update(data){
        if(data.client.portfolioSettings) {
          data.client.portfolioSettings = data.client.portfolioSettings.map((item, idx) => {
            return {...item, idx}
          })
        }
        return data.client
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>
.btn-validation{
 width: 100%;
}
</style>
